import LinkBar_ResponsiveComponent from '@wix/thunderbolt-elements/src/components/LinkBar/viewer/skinComps/Responsive/Responsive.skin';
import LinkBar_ResponsiveController from '@wix/thunderbolt-elements/src/components/LinkBar/viewer/LinkBar.controller';


const LinkBar_Responsive = {
  component: LinkBar_ResponsiveComponent,
  controller: LinkBar_ResponsiveController
};


export const components = {
  ['LinkBar_Responsive']: LinkBar_Responsive
};

